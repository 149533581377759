import React, { Suspense, useState } from "react";

import "../Styles/Home.css";

// homepage slider

import posterVideo from "../Assets/MBF2025.jpeg";
import mobileBanner from "../Assets/MBF2025.jpeg";

// props
import divider from "../Assets/Home/divider.svg";
import sparkler from "../Assets/sparkler.svg";

import vynil from "../Assets/Home/vynil.svg";
import sound from "../Assets/Home/sound.svg";
import illustrationProps from "../Assets/Props/IllustrationFirst.svg";
import propsSecond from "../Assets/Props/propsSecond.svg";

import muted from "../Assets/mute.png";
import volume from "../Assets/volume.png";

// social icons
import ig from "../Assets/Home/social/ig.svg";
import fb from "../Assets/Home/social/fb.svg";
import twitter from "../Assets/Home/social/twitter.svg";
import youtube from "../Assets/Home/social/youtube.svg";
import propLeftSystem from "../Assets/Home/social/propLeftSystem.svg";
import propRightSystem from "../Assets/Home/social/propRightSystem.svg";

// lineup

import lineup2024 from "../Assets/lineup/Lineup2024.png";

// Blues in the news
import img01 from "../Assets/Home/news/img01.png";
import img02 from "../Assets/Home/news/img02.png";
import img03 from "../Assets/Home/news/img03.png";
import img04 from "../Assets/Home/news/img04.png";
import img05 from "../Assets/Home/news/img05.png";
import img06 from "../Assets/Home/news/img06.png";
import img07 from "../Assets/Home/news/img07.png";
import img08 from "../Assets/Home/news/img08.png";



// blues art
import BA24 from "../Assets/BluesArt/ba24.png";
import BA23 from "../Assets/BluesArt/ba23.png";
import BA20 from "../Assets/BluesArt/ba20.png";
import BA19 from "../Assets/BluesArt/ba19.png";
import BA18 from "../Assets/BluesArt/ba18.png";
import BA17 from "../Assets/BluesArt/ba17.png";
import BA16 from "../Assets/BluesArt/ba16.png";
import BA15 from "../Assets/BluesArt/ba15.png";
import BA14 from "../Assets/BluesArt/ba14.png";
import BA13 from "../Assets/BluesArt/ba13.png";
import BA12 from "../Assets/BluesArt/ba12.png";
import BA11 from "../Assets/BluesArt/ba11.png";

// partners
import toi from "../Assets/partners/toi.svg";
import glenlivet from "../Assets/partners/glenlivet.svg";
import gledo from "../Assets/partners/gledo.png";
import cosponsortop from "../Assets/Home/cosponsortop.svg";
import cosponsorbt from "../Assets/Home/cosponsorbt.svg";
import ap from "../Assets/Home/ap.svg";
import hp from "../Assets/Home/hp.svg";
import mobilesponsor from "../Assets/Home/mobilesponsor.svg";
import bms from "../Assets/Home/bms.svg";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Row, Col, Nav, Tab, Tabs } from "react-bootstrap";

const GalleryMain = React.lazy(() => import("./HomeGallery"));

const Index = () => {
  const [isMuted, setIsMuted] = useState(true);
  const video = document.getElementById("video_bg");
  return (
    <div>
      {/* homepeage banner */}
      <section className="MainBanner">
        <div className="landing_banner dView">
          {/* <video
            id="video_bg"
            playsInline="playsinline"
            poster={posterVideo}
            style={{ width: "100%" }}
            preload={true}
            muted={isMuted}
            autoPlay={true}
            loop="loop"
          >
            <source src={videoHome} type="video/webm"></source>
            
          </video> */}

          <img src={posterVideo} alt="mainposter" 
              
              style={{ height: "100%", width: "100%", objectFit: "contain", paddingBottom: "8px" }}
            />

            
 

          {/* <div
            className="work-muted"
            onClick={() => setIsMuted(() => !isMuted)}
          >
            <img
              src={isMuted ? muted : volume}
              style={{ height: "100%", width: "100%", objectFit: "contain" }}
            />
          </div> */}

        </div>


        <div className="landing_banner mView">

          <img src={mobileBanner} alt="mainposter" 
              
              style={{ height: "100%", width: "100%", objectFit: "contain", paddingBottom: "8px" }}
            />

        </div>
      </section>
      {/* About Festival */}
      <section className="aboutBlock">
        <Row>
          <Col sm={12} xs={12} md={12} lg={4} xl={4}>
            <div
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="3000"
            >
              <h1 className="headingMbf">Mahindra Blues Festival</h1>
            </div>
            <div>
              <img src={sparkler} className="sparklerStyle" alt="prop" />
              <img src={sparkler} className="sparklerStyle" alt="prop" />
              <img src={sparkler} className="sparklerStyle" alt="prop" />
              <img src={sparkler} className="sparklerStyle" alt="prop" />
            </div>

            <div className="propsFirst">
              <img
                data-aos="zoom-in"
                data-aos-anchor-placement="center-bottom"
                data-aos-duration="3000"
                src={illustrationProps}
                className="propsOneStyle"
                alt="prop illustrationProps"
              />
            </div>
          </Col>
          <Col sm={12} xs={12} md={12} lg={8} xl={8}>
            <div data-aos="fade-up" data-aos-duration="1500" className="dView">
              <Tab.Container id="left-tabs-example" defaultActiveKey="AboutFestival">
                <Row>
                  <Col sm={12}>
                    <Nav variant="pills" className="">
                      {/* <Nav.Item>
                        <Nav.Link className="tabStyle" eventKey="LineUp">
                          Line Up
                        </Nav.Link>
                      </Nav.Item> */}
                      <Nav.Item>
                        <Nav.Link className="tabStyle" eventKey="AboutFestival">
                          About Festival
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          href="https://in.bookmyshow.com/events/mahindra-blues-festival-2025/ET00420656?webview=true"
                          className="tabStyle"
                          target="_blank"
                        >
                          Get Tickets
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12} className="spaceTabsAbout dView">
                    <Tab.Content className="contentHeight">
                      <Tab.Pane eventKey="AboutFestival">
                        <div className="aboutFestivalCard">
                          <h2 className="aboutCardHeading">
                            "THE BLUES LIVE HERE"
                          </h2>
                          
                          <p className="paragraph">
                          The Mahindra Blues Festival is back, and it's hitting the stage for its 13th year with all the soulful, foot-tapping, and heart-wrenching blues vibes that have made it a legendary event. This annual celebration has etched its name into the soul of Mumbai at the iconic Mehboob Studios, Bandra, becoming a timeless tradition that keeps blues lovers coming back for more.
<br></br>
<br></br>
The festival's legacy is a vibrant blend of gritty narratives, soul-stirring rhythms, and the artists who've etched their tales on its stage over time. It's a testament to the enduring magnetism of blues, a genre that digs into your soul, evoking emotions that resonate with all music lovers. 
<br></br>
<br></br>
As the 13th edition takes the stage, prepare to be carried away by the raw, evocative power of the blues with some of the more iconic performing live. It's not just a festival; it's a timeless journey through the heart and soul of a genre that transcends time and place, leaving an indelible mark on all who experience it.
<br></br>
<br></br>
Stay tuned for a power-packed line-up dropping soon!
                          </p>
                          <div className="spaceTopBtn">
                            <a href="/aboutus" className="btnBlue">
                              Know More
                            </a>
                          </div>
                        </div>
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="LineUp">
                        <div className="bgLineUpHomeCard">
                          <h2 className="aboutCardHeading center blacktxt">Line Up</h2>

                          <div className="bgLineUpHome">
                          <div className="dayInfoOne">
                                  <img
                                    src={lineup2024}
                                    className="dayWiseLU"
                                    alt="Day One Line up"
                                  />
                                </div>
                            

                          </div>
                          <div className="spaceTopBtn center">
                            <a href="/artistinfo_schedule" className="btnBlue">
                              Know More
                            </a>
                          </div>
                        </div>
                      </Tab.Pane> */}
                      <Tab.Pane eventKey="GetTickets">
                        <div className="aboutFestivalCard">
                          <h2 className="aboutCardHeading">Get Tickets</h2>
                          <div className="spaceTopBtn">
                            <a
                              target="_blank"
                              href="https://in.bookmyshow.com/events/mahindra-blues-festival-2025/ET00420656?webview=true"
                              className="btnBlue"
                            >
                              Get Tickets
                            </a>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </Col>
          <Col className="mView" sm={12} xs={12}>
            {/* Line Up */}
            {/* <div className="lineUpMobile">
              <h2 className="aboutCardHeadMobile center">Line Up</h2>
              <div className="mb42">
                <div>
                <div className="lineupCard">
                    <img
                      src={lineup2024}
                      className="dayWiseLU"
                      alt="Day One Line up"
                    />
                  </div>
                  

                </div>
                <div className="spaceTopBtn center">
                  <a href="/artistinfo_schedule" className="btnBlue">
                    Know More
                  </a>
                </div>
              </div>
            </div> */}
            <div className="aboutFestivalCard">
              <h2 className="aboutCardHeading">About Festival</h2>
              <p
                data-aos="fade-up"
                data-aos-duration="2000"
                className="home_para_bold"
              >
                "THE BLUES LIVE HERE"
              </p>
              <p className="paragraph">
              The Mahindra Blues Festival is back, and it's hitting the stage for its 13th year with all the soulful, foot-tapping, and heart-wrenching blues vibes that have made it a legendary event. This annual celebration has etched its name into the soul of Mumbai at the iconic Mehboob Studios, Bandra, becoming a timeless tradition that keeps blues lovers coming back for more.
<br></br>
<br></br>
The festival's legacy is a vibrant blend of gritty narratives, soul-stirring rhythms, and the artists who've etched their tales on its stage over time. It's a testament to the enduring magnetism of blues, a genre that digs into your soul, evoking emotions that resonate with all music lovers. 
<br></br>
<br></br>
As the 13th edition takes the stage, prepare to be carried away by the raw, evocative power of the blues with some of the more iconic performing live. It's not just a festival; it's a timeless journey through the heart and soul of a genre that transcends time and place, leaving an indelible mark on all who experience it.
<br></br>
<br></br>
Stay tuned for a power-packed line-up dropping soon!
              </p>
              <div className="spaceTopBtn">
                <a href="/aboutus" className="btnBlue">
                  Know More
                </a>
              </div>
            </div>
            <br></br>
            <br></br>
            {/* Get Tickets */}
            <div className="getTicketsMobile">
              <h2 className="aboutCardHeadMobile">Get Tickets</h2>
              <div className="spaceTopBtn">
                <a
                  target="_blank"
                  href="https://in.bookmyshow.com/events/mahindra-blues-festival-2025/ET00420656?webview=true"
                  className="btnBlue"
                >
                  Get Tickets
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </section>
      {/* Ticker Block */}
      <section className="tickerBlock">
        <a href="#">
          <div className="moving">
            <div className="moving-text" aria-hidden="true">
              <span>
                When I'm singing the blues, I'm singing life." ~ Etta James
              </span>
              <img src={vynil} className="tickerProp"></img>
              <span>
                {" "}
                When I'm singing the blues, I'm singing life." ~ Etta James
              </span>
              <img src={sound} className="tickerProp"></img>
              <span>
                {" "}
                When I'm singing the blues, I'm singing life." ~ Etta James
              </span>
              <img src={sound} className="tickerProp"></img>
              <span>
                When I'm singing the blues, I'm singing life." ~ Etta James
              </span>
              <img src={vynil} className="tickerProp"></img>
              <span>
                {" "}
                When I'm singing the blues, I'm singing life." ~ Etta James
              </span>
              <img src={sound} className="tickerProp"></img>
              <span>
                {" "}
                When I'm singing the blues, I'm singing life." ~ Etta James
              </span>
              <img src={sound} className="tickerProp"></img>
            </div>
          </div>
        </a>
      </section>
      {/* Band Hunt */}
      {/* main stage */}
      <section className="mainStageBlock">
        <div className="mbfContainer">
          <h3
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="3000"
            className="headingBeta centerAlign"
          >
            BLUES ART
          </h3>
          <div class="wrapperBanner">
            <div class="photobanner">
              <img class="first" src={BA24} alt="" />
              <img src={BA23} alt="" />
              <img src={BA20} alt="" />
              <img src={BA24} alt="" />
              <img src={BA19} alt="" />
              <img src={BA18} alt="" />
              <img src={BA17} alt="" />
              <img src={BA16} alt="" />
              <img src={BA15} alt="" />
              <img src={BA14} alt="" />
              <img src={BA13} alt="" />
              <img src={BA12} alt="" />
              <img src={BA11} alt="" />
              <img src={BA23} alt="" />
              <img src={BA20} alt="" />
              <img src={BA19} alt="" />
              <img src={BA18} alt="" />
              <img src={BA17} alt="" />
              <img src={BA16} alt="" />
              <img src={BA15} alt="" />
              <img src={BA14} alt="" />
              <img src={BA13} alt="" />
              <img src={BA12} alt="" />
            </div>
          </div>
          <div className="spaceTopBtn centerAlign">
            <a href="/bluesart" className="btnWhite">
              Visit Blues Art
            </a>
          </div>
        </div>
      </section>
      <section className="bandHuntBlock">
        <div className="bandHuntTxt">
        <h3
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="3000"
            className="headingBeta centerAlign"
          >
           BAND HUNT
          </h3>
          <p className='comingSoonTxtDesktopba'>Coming Soon</p>

          {/* <Row>
            <Col sm={12} xs={12} md={12} lg={6} xl={6}>
              <div>
              <p data-aos="fade-up"
            data-aos-duration="2000" className="BandHuntHead">Band Hunt</p>
                <p data-aos="fade-up"
            data-aos-duration="2000" className="bandHuntHeadInfo">THE BIG BLUES BAND HUNT 2023</p>
                <p data-aos="fade-up"
            data-aos-duration="2000" className="bandHuntParaInfo">Keeping up with traditions, this year’s Mahindra Blues Festival band hunt saw three versatile bands battling it to get a spot at the festival - The Karan Mahajan Band (Delhi), The Blues Conscience(Chennai) and Leonardo and his Band (Delhi). The winner gets a slot at the Garden Stage at the festival. The finale of the hunt was set with the three bands performing live at AntiSocial, Mumbai on the 16th of January. Our judges Ehsaan Noorani and Loy Mendonsa judged the evening and crowned The Karan Mahajan Band the winners of the Big Blues Band Hunt 2023.</p>
                <p data-aos="fade-up"
            data-aos-duration="2000" className="bandHuntParaInfoSub">Catch the winners perform live at the Mahindra Blues Festival on both 11th and 12th of February 2023 at the Garden Stage!</p>
              </div>
            </Col>
            <Col sm={12} xs={12} md={12} lg={6} xl={6}>
              <div>
                <img data-aos="fade-up"
            data-aos-duration="2000" className="bandHuntImgInfo" src={karanMahajan} alt="The Karan Mahajan Band"></img>
              </div>
            </Col>
          </Row> */}
        </div>
      </section>
      {/* props */}
      <div className="secondProps">
        <img
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="3000"
          src={propsSecond}
          className="SecondAnimate vert-move"
          alt="props illustration console"
        />
      </div>
      {/* Blues in the news */}
      <section className="BluesNewsBlock">
        <h3
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="3000"
          className="headingBeta"
        >
          BLUES IN THE NEWS
        </h3>
        <div>
          <Swiper
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Pagination, Autoplay, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                className="newsCard"
              >
                <img src={img01} alt="news alt" className="imageNews"></img>
                <div>
                  <a href="/legacy" target="_blank" className="newsCardTitle">
                    Venky Pens The Blue
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                className="newsCard"
              >
                <img src={img02} alt="news alt" className="imageNews"></img>
                <div>
                  <a href="/bluesart" target="_blank" className="newsCardTitle">
                    The Blues Art 2019 Ashwam Shrikant Salokhe
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                className="newsCard"
              >
                <img src={img03} alt="news alt" className="imageNews"></img>
                <div>
                  <a
                    href="/memphismachine"
                    target="_blank"
                    className="newsCardTitle"
                  >
                    Venky aens The Blue The Memphis Machine
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                className="newsCard"
              >
                <img src={img04} alt="news alt" className="imageNews"></img>
                <div>
                  <a
                    href="/sugaraypunch"
                    target="_blank"
                    className="newsCardTitle"
                  >
                    Venky Pens The Blue The Sugaray 'Punch'
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                className="newsCard"
              >
                <img src={img05} alt="news alt" className="imageNews"></img>
                <div>
                  <a
                    href="/whitebluesman"
                    target="_blank"
                    className="newsCardTitle"
                  >
                    Venky Pens The Blue The Dawn of the 'White Bluesman'
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                className="newsCard"
              >
                <img src={img06} alt="news alt" className="imageNews"></img>
                <div>
                  <a className="newsCardTitle">
                    Venky Pens The Blue Mayal Mania
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                className="newsCard"
              >
                <img src={img07} alt="news alt" className="imageNews"></img>
                <div>
                  <a className="newsCardTitle">
                    Venky Pens The Blue A Once ia a Blue Moon Opportunity
                  </a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                className="newsCard"
              >
                <img src={img08} alt="news alt" className="imageNews"></img>
                <div>
                  <a className="newsCardTitle">
                    Venky Pens The Blue Coco's Blues
                  </a>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      {/* divider */}
      <img src={divider} className="dividerStyle" alt="mbf seprator"></img>
      {/* gallery home  */}
      <Suspense fallback={<div>Loading...</div>}>
        <section className="galleryHomeBlock">
          <div>
            <h3
              data-aos="fade-up"
              data-aos-anchor-placement="center-bottom"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="3000"
              className="headingBeta centerAlign"
            >
              PHOTO GALLERY
            </h3>
          </div>
          <div className="imageGalleryBlock">
            <GalleryMain />
          </div>
          <div className="spaceTopBtn centerAlign">
            <a href="/photogallery" className="btnWhite">
              View Photo Gallery
            </a>
          </div>
        </section>
      </Suspense>
      {/* Buzz block */}
      {/* <section className='buzzBlock'>
                <Row>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <h3 data-aos="fade-up"
                            data-aos-anchor-placement="center-bottom" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className='headingBeta'>FOLLOW US ON</h3>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={6}>
                        <div className='alignRight dView'>
                            <a href='https://www.instagram.com/mahindrablues/' target="_blank" className='instaSpace'>
                                <img src={instaIcon} alt="instaIcon" className='smallInsta' />
                                mahindrabluesfestival
                            </a>
                        </div>
                    </Col>
                </Row>
                <Row className='spaceTopPhotos'>
                    <Col sm={6} xs={6} md={3} lg={3} xl={3} className="centerAlign">
                        <img data-aos="fade-up"
                            data-aos-anchor-placement="center-bottom" data-aos-easing="ease-out-cubic"
                            data-aos-duration="1500" src={img001} alt="dummyImg" className='dummyImgInsta' />
                    </Col>
                    <Col sm={6} xs={6} md={3} lg={3} xl={3} className="centerAlign">
                        <img data-aos="fade-up"
                            data-aos-anchor-placement="center-bottom" data-aos-easing="ease-out-cubic"
                            data-aos-duration="1500" src={img002} alt="dummyImg" className='dummyImgInsta' />
                    </Col>
                    <Col sm={6} xs={6} md={3} lg={3} xl={3} className="centerAlign">
                        <img data-aos="fade-up"
                            data-aos-anchor-placement="center-bottom" data-aos-easing="ease-out-cubic"
                            data-aos-duration="1500" src={img003} alt="dummyImg" className='dummyImgInsta' />
                    </Col>
                    <Col sm={6} xs={6} md={3} lg={3} xl={3} className="centerAlign">
                        <img data-aos="fade-up"
                            data-aos-anchor-placement="center-bottom" data-aos-easing="ease-out-cubic"
                            data-aos-duration="1500" src={img004} alt="dummyImg" className='dummyImgInsta' />
                    </Col>
                </Row>
                <div className='alignRight mView'>
                    <a href='#' className='instaSpace'>
                        <img src={instaIcon} alt="instaIcon" className='smallInsta' />
                        mahindrabluesfestival
                    </a>
                </div>
            </section> */}

      {/* Check us out at */}
      <section className="socialConnectBlock">
        <div>
          <h3
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="3000"
            className="headingBeta centerAlign"
          >
            CHECK US OUT AT
          </h3>
          <div
            data-aos="zoom-in"
            data-aos-easing="ease-out-cubic"
            className="iconsBlock"
          >
            <a href="https://www.instagram.com/mahindrablues/" target="_blank">
              <img src={ig} className="connectIcons spaceConnectsm"></img>
            </a>
            <a href="https://www.facebook.com/MahindraBlues" target="_blank">
              <img src={fb} className="connectIcons spaceConnectsm"></img>
            </a>
            <a href="https://twitter.com/mahindrablues" target="_blank">
              <img src={twitter} className="connectIcons spaceConnectsm"></img>
            </a>
            <a
              href="https://www.youtube.com/user/mahindrablues"
              target="_blank"
            >
              <img src={youtube} className="connectIcons"></img>
            </a>
          </div>
          <img src={propLeftSystem} className="propLeftC dView"></img>
          <img src={propRightSystem} className="propRightC dView"></img>
        </div>
      </section>
      <section className="sponsorsBlock">
        <div>
          <h3
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="3000"
            className="headingBeta centerAlign"
          >
            OUR PARTNERS
          </h3>
          <div className="logoContainer">
          <div className="partnersCont mbft">
                  <p className="partnersTag">Co Sponsors</p>
                  
                  <img
                    src={mobilesponsor}
                    alt="Co Sponsors Logo "
                    className="partnersLogo spacePartner mobile"
                  ></img>
                  <img
                    src={cosponsortop}
                    alt="Co Sponsors Logo "
                    className="partnersLogo spacePartner mhid"
                  ></img>
                  <br></br>
                  <br></br>
                   <img
                    src={cosponsorbt}
                    alt="Co Sponsors Logo "
                    className="partnersLogo spacePartner mhid"
                  ></img>
                </div>
            <Row className="spaceTopPhotos">
            <Col sm={12} xs={12} md={4} lg={4} xl={4}>
                <div className="partnersCont mbftmh">
                  <p className="partnersTag">Automobile Partner</p>
                  <img
                    src={ap}
                    alt="car&bike"
                    className="partnersLogomb spacePartner"
                  ></img>
                </div>
              </Col>
              <Col sm={12} xs={12} md={4} lg={4} xl={4}>
                <div className="partnersCont mbftmh">
                  <p className="partnersTag">In Association with</p>
                  <img
                    src={toi}
                    alt="the times of india"
                    className="partnersLogombt spacePartner"
                  ></img>
                </div>
              </Col>
              <Col sm={12} xs={12} md={4} lg={4} xl={4}>
                <div className="partnersCont mbftmh">
                  <p className="partnersTag">Ticketing Partner</p>
                  <img
                    src={bms}
                    alt="bookmyshow"
                    className="partnersLogombms spacePartner"
                  ></img>
                </div>
              </Col>
            </Row>
            <Row className="spaceTopPhotos">
            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
              </Col>
            <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                <div className="partnersCont mbftmh">
                  <p className="partnersTag">Promoted & Produced By</p>
                  <img
                    src={hp}
                    alt="hyperlink"
                    className="partnersLogombh spacePartner"
                  ></img>
                </div>
              </Col>
              <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                <div className="partnersCont mbftmh">
                  <p className="partnersTag">Outdoor Partner</p>
                  <img
                    src={gledo}
                    alt="gledo"
                    className="partnersLogombh spacePartner"
                  ></img>
                </div>
              </Col>
              <Col sm={12} xs={12} md={3} lg={3} xl={3}>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Index;
