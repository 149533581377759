import React from "react";
import "../Styles/ArtistInfo.css";
import { Row, Col } from "react-bootstrap";



import robertrandolph from "../Assets/lineup/new/robertrandolph_1@2x.png";
import theteskeybrothers from "../Assets/lineup/new/Teskey@2x.png";
import kanchandaniel from "../Assets/lineup/new/kanchandaniel@2x.png";
import ruthiefoster from "../Assets/lineup/new/ruthiefoster@2x.png";
import meganmurray from "../Assets/lineup/new/meganmurray@2x.png";
import tajfarrant from "../Assets/lineup/new/tajfarrant@2x.png";

import bethhart from "../Assets/lineup/new/bh.png";
import dana from "../Assets/lineup/new/df.png";
import mamatips from "../Assets/lineup/new/mtatmb.png";
import sheryl from "../Assets/lineup/new/sy.png";
import vanessa from "../Assets/lineup/new/vc.png";
import samantha from "../Assets/lineup/new/sf.png";

// 
function myFunction() {
  var dots = document.getElementById("dots");
  var moreText = document.getElementById("more");
  var btnText = document.getElementById("myBtn");

  if (dots.style.display === "none") {
    dots.style.display = "inline";
    btnText.innerHTML = "Read more"; 
    moreText.style.display = "none";
  } else {
    dots.style.display = "none";
    btnText.innerHTML = "Read less"; 
    moreText.style.display = "inline";
  }
}
// 2
function myFunction1() {
  var dots1 = document.getElementById("dots1");
  var moreText1 = document.getElementById("more1");
  var btnText1 = document.getElementById("myBtn1");

  if (dots1.style.display === "none") {
    dots1.style.display = "inline";
    btnText1.innerHTML = "Read more"; 
    moreText1.style.display = "none";
  } else {
    dots1.style.display = "none";
    btnText1.innerHTML = "Read less"; 
    moreText1.style.display = "inline";
  }
}
// 3
function myFunction2() {
  var dots2 = document.getElementById("dots2");
  var moreText2 = document.getElementById("more2");
  var btnText2 = document.getElementById("myBtn2");

  if (dots2.style.display === "none") {
    dots2.style.display = "inline";
    btnText2.innerHTML = "Read more"; 
    moreText2.style.display = "none";
  } else {
    dots2.style.display = "none";
    btnText2.innerHTML = "Read less"; 
    moreText2.style.display = "inline";
  }
}
// 4
function myFunction3() {
  var dots3 = document.getElementById("dots3");
  var moreText3 = document.getElementById("more3");
  var btnText3 = document.getElementById("myBtn3");

  if (dots3.style.display === "none") {
    dots3.style.display = "inline";
    btnText3.innerHTML = "Read more"; 
    moreText3.style.display = "none";
  } else {
    dots3.style.display = "none";
    btnText3.innerHTML = "Read less"; 
    moreText3.style.display = "inline";
  }
}




function ArtistInfo() {
  return (
    <div>
      <section className="artist_info_main">
        <div className="mbspace">
          <h3
            data-aos="fade-up"
            data-aos-easing="ease-in"
            data-aos-duration="2000"
            className="galleryMainHead"
          >
            ARTIST LINEUP
          </h3>
          {/* <p className='comingSoonTxtDesktopba'>Coming Soon</p> */}
        </div>


         <Row
          data-aos="fade-up"
          data-aos-duration="2000"
          className="alignMiddle"
        >
          <Col sm={12} xs={12} md={4} xl={4} lg={4}>
            <div className="center">
              <img
                src={robertrandolph}
                alt="Robert Randolph"
                className="new_img_ar"
              ></img>
            </div>
          </Col>
          <Col sm={12} xs={12} md={8} xl={8} lg={8}>
            <p className="artistnamem">Robert Randolph</p>
            <p className="aboutartist">
            What do classic rock heavyweights Elton John, Eric Clapton, Carlos Santana, singer-songwriters Dave Matthews, John Mayer, Leanne Rhymes, Darius Rucker, and some of the biggest arena rock acts like Ozzy Osbourne and Metallica all have in common?  At some point in their career they wanted to incorporate the singing sound of the pedal steel guitar, and they all called in the same person to do it: Robert Randolph. 

            <br></br><br></br>
            A virtuoso on the pedal steel guitar, Robert Randolph burst onto the music scene in the early 2000’s with his fiery, passionate instrumental work and heartfelt music.  It didn’t take long for Robert’s talents to get in front of numerous guitar legends, including Eric Clapton.  Upon first listen, Clapton tapped Robert Randolph & the Family Band to tour the world opening for his band and include him in every iteration of the legendary Crossroads Festival.  Clapton would also appear on Robert Randolph’s album “Colorblind” released in 2006 on Warner Music, a record that would also include a guest appearance from Dave Matthews.  

            <br></br><br></br>
            Robert’s innovative approach to the pedal steel guitar has earned him four Grammy nominations, multiple Blues Music Awards, a BMI award, and Rolling Stone named Randolph one of the "100 Greatest Guitarists of All Time”.  His work can be heard on numerous tv and film broadcasts, including his long standing theme song for the New York Knicks “Get There”, and “Ain’t Nothing Wrong With That”, the current theme song for NFL Thursday Night Football . 

            <span id="dots">...</span><span id="more">
            While Randolph cut his teeth playing in services at the House of God Church in his native New Jersey (a domination where the pedal steel is a key part of the musical program), his talents were discovered by fans outside the church, and he made his debut with a live album recorded with his backing group the Family Band, 2001's Live at the Wetlands, that mixed gospel and full-bodied rock and blues. Randolph's first studio set, 2003's Unclassified, broadened the scope of his repertoire with flashes of funk and Caribbean sounds. His subsequent albums with the Family Band, such as 2010's When We Walk This Road (produced by T-Bone Burnett) and 2013's Lickety Split, were steeped in passion and energy that came from their heartfelt fusion of multiple genres. 

            <br></br><br></br>
            Off stage, Robert has a long demonstrated history of community involvement and driving meaningful social change. He utilizes his passion for humanity and his diverse talents to bring people of all backgrounds together to create empowered communities that allow everyone to live their best lives. 
 
            <br></br><br></br>
            These passions inspired him to found the Robert Randolph Foundation.  Focused on creating platforms for youth to gain knowledge and transform their lives regardless of their cultural, social, or racial backgrounds,  RRF is set out to bring people of diverse backgrounds together at events such as 2020's inaugural Juneteenth Fest. 
            </span>
            <button onClick={myFunction} className="stylereadmrebtn" id="myBtn">Read more</button>
            </p>
            
          </Col>
        </Row>

        <Row
          data-aos="fade-up"
          data-aos-duration="2000"
          className="alignMiddle spacearl"
        >
          <Col className="mobile" sm={12} xs={12} md={4} xl={4} lg={4}>
            <div className="center">
              <img
                src={theteskeybrothers}
                alt=" The Teskey Brothers"
                className="new_img_ar"
              ></img>
            </div>
          </Col>
          <Col sm={12} xs={12} md={8} xl={8} lg={8}>
            <p className="artistnamem"> The Teskey Brothers</p>
            < p className = "aboutartist" >
            Over the past 10 years Sam and Josh Teskey, known around the world as The Teskey Brothers, have captured the hearts of many, reaching the kind of global success a blues band out of Melbourne could only have dreamed of. Across three studio albums, their soul-soaked spin on rock, folk and blues has struck an undeniable chord with music lovers around the world. 2023's The Winding Way being no exception, landing at #1 on the ARIA album chart with 4 nominations and a win, receiving Best Album and critical acclaim across the globe. For the majority of 2023 and 2024, they've played sold out headline shows across North America, Europe and the UK (including a notable 17,000 in Amsterdam and 15,000 in London), iconic festivals such as Glastonbury, Electric Picnic, Montreux Jazz, Merlefest, Austin City Limits, and even opened for Bruce Springsteen in Milan.
<br></br>
<br></br>
Early 2025 will see the brothers embark on an exciting run of shows; firstly on home shores for A Day On The Green, and then onwards for a much anticipated return to New Zealand. With yet more dates to be announced around the world, The Teskey Brothers along with their full 7-piece band- are a rare and mesmerising experience which is simply not to be missed.

            </p>
          </Col>
          <Col className="desktop" sm={12} xs={12} md={4} xl={4} lg={4}>
            <div className="center">
              <img
                src={theteskeybrothers}
                alt="theteskeybrothers"
                className="new_img_ar"
              ></img>
            </div>
          </Col>
        </Row>

        <Row
          data-aos="fade-up"
          data-aos-duration="2000"
          className="alignMiddle"
        >
          <Col sm={12} xs={12} md={4} xl={4} lg={4}>
            <div className="center">
              <img
                src={ruthiefoster}
                alt="Ruthie Foster"
                className="new_img_ar"
              ></img>
            </div>
          </Col>
          <Col sm={12} xs={12} md={8} xl={8} lg={8}>
            <p className="artistnamem">Ruthie Foster</p>
            <p className="aboutartist">
            Ruthie Foster's musical journey is as eclectic and vibrant as her soulful voice. Born into a gospel-rich family in Gause, Texas, Ruthie was influenced by her mother, grandmother, and artists like the Gospel Keynotes and the Blind Boys of Alabama. Initially shy, Ruthie embraced her talent, thanks to encouragement from her family and church community. 

            <br></br>
            <br></br>
            After studying music and serving in the Navy, where she performed with the Navy band Pride, Ruthie signed a development deal with Atlantic Records and honed her skills in New York City's songwriting community. She eventually returned to Texas, blending blues, folk, and gospel into a unique sound. Ruthie's acclaimed albums include Runaway Soul, The Phenomenal Ruthie Foster, The Truth According to Ruthie Foster, and Healing Time. She has collaborated with artists like Bonnie Raitt, Susan Tedeschi, and Derek Trucks, further highlighting her versatility. With five Grammy nominations and numerous awards, she remains a powerful voice in music. 

            <br></br>
            <br></br>
            Her latest album, Mileage, to be released on the legendary Sun Records, reflects on her life and musical journey. Produced and co-written by Tyler Bryant and co-written with Rebecca Lovell (Bryant's wife and one-half of Larkin Poe), the album features powerful tracks like "Rainbow" and "Heartshine."  

            <span id="dots2">...</span><span id="more2">
            Ruthie’s rich storytelling and authenticity have captivated and inspired audiences worldwide. With Mileage, she invites listeners on a soul-stirring voyage, echoing her rich heritage and life experiences. 

              <br></br>
              <br></br>
            </span>
            <button onClick={myFunction2} className="stylereadmrebtn" id="myBtn2">Read more</button>
            </p>
          </Col>
        </Row>

        <Row
          data-aos="fade-up"
          data-aos-duration="2000"
          className="alignMiddle spacearl"
        >
          <Col className="mobile" sm={12} xs={12} md={4} xl={4} lg={4}>
            <div className="center">
              <img
                src={tajfarrant}
                alt="Taj Farrant"
                className="new_img_ar"
              ></img>
            </div>
          </Col>
          <Col sm={12} xs={12} md={8} xl={8} lg={8}>
            <p className="artistnamem">Taj Farrant</p>
            <p className="aboutartist">
            The 15-year-old artist’s music seems seasoned and mature, and his debut album, ‘Chapter One,’ is the testimony. After performing on global stages like Lollapalooza, Taj is bringing the magic of electrified blues, which is reminiscent of young Joe Bonamassa at the Soulstrat Stage on the first day of the festival.

            </p>
            {/* <p className="aboutartist">
            Growing up in a musically inclined family with her mother having her own female group “The Royal Harmonetts” and being a pianist and vocalist in the church, Sheryl was born into music. 
            <br></br>
            2015 Chicago Blues Hall Of Fame Inductee. Sheryl began singing and playing various instruments in the church, in her teen years Sheryl began her own female group in her church (Mt. Zion Baptist Church) called “Sisters” in which she wrote several of their songs and also wrote songs for the National Baptist State Convention, (unfortunately those songs were not recorded) soon after Sheryl auditioned for and was immediately excepted into the world renowned gospel recording group “Rev. Milton Brunson and the Thompson Community Singers of Chicago” affectionately called “The Tommies” while singing with “The Tommies” the group became a Grammy and Stella Award winning group. During that time Sheryl began playing drums and or singing with various recording artist such as Otis Clay, Artie “BLUES BOY” White, Bobby “Slim” James, “KoKo Taylor and “The KoKo Taylor Blues Foundation”, Nellie “Tiger” Travis, Willie Rogers, 3x Hall of Famer Billy Shelton, 3x Stella Award winner “Darious Brooks”,
            <span id="dots1">...</span><span id="more1">

             Hall of Fame Group “The Spaniels, “Raven” Grammy and Emmy Award winner Jennifer Hudson, Yolanda Adams, Daryl Coley, LeAnn Faine, Stormy Weather, she has opened for The Whispers, Buddy Guy, Lakeside, Chante Moore, Reheem De’Vaughn, Kindred The Family Soul, Denise LaSalle, Bobby Rush, John Primer, Maurice John Vaughn and the list goes on in 1995 Sheryl Formed “SAYYES!” band and an all female band Ultimately Blessed a.k.a. “UB”  SAY YES! And UB performed at many different venue’s and put on yearly benefit concerts for SWWR (Batter and abused Women and Children Facility) Cancer; Sickle cell Anemia; Diabetes; Prostate Cancer and more. SAYYES! the band continues to play a variety of music, a variety of venues and for a variety of events SAYYES! has opened for many artist as mentioned on the SAYYES! page of this website www.sayyeslive.com the band continues on, making and playing music. Sheryl has been on Radio, Television, in multiple Blues magazines and in digital media. 
            
            <br></br>
            <br></br>
            Sheryl started her own Blues band Sheryl Youngblood Blues Band in 2013 A Powerful Vocalist; Drummer; Songwriter; Bandleader; Entertainer. works regularly and travels extensively. Performing at such places as The world renown BLUE CHICAGO as a regular artist of the club as well as playing at many venues in and out of the United State with bookings at Buddy Guys Legend, Rosa’s Lounge, House of Blues Main Stage, Crossroads Stage and Foundation Room, Kingston Mines, B.L.U.E.S on Halsted, Taste of Chicago, Taste of East Chicago, Blue Chip Casino, Majestic Star Casino, Hardrock Casino MWI, Muckleshoot Casino Seattle Washington, Ameristar Casino Jackson Mississippi, Country Club Hills Theater , Blues On White Edmonton Canada, Blues Can Calgary Canada, and many more venues, keeping the audience involved and energized with a TRUE show. Sheryl also sings and performs with world renowned group, Mississippi Heat 
            <br></br>
            <br></br>
            A/V (Audio/Visual): Attended school of Can TV: Videography; Video recording and editing and is now certified as a videography and Audio technician Working in the music business has afforded Sheryl many opportunities for hands on training and teaching of Audio, Video, lighting, spot lighting and as a full on stagehand with Hands R Us Staging for multiple venues: Country club hills Theater; House of Hope special events to name just a few. 
            <br></br>
            <br></br>
            Discography: Stranger In My Bed, Live at House of Blues with her rearrangement of At Last by Etta James and other songs you will love to listen to and soon hear in the Blues and R&B sector. 

            Sheryl’s mission is to touch everyone possible musically even if only for an hour or two on stage making someone feel better then they did when they arrived to the event! </span>
            <button onClick={myFunction1} className="stylereadmrebtn" id="myBtn1">Read more</button>
            </p> */}
          </Col>
          <Col className="desktop" sm={12} xs={12} md={4} xl={4} lg={4}>
            <div className="center">
              <img
                src={tajfarrant}
                alt="taj farrant"
                className="new_img_ar"
              ></img>
            </div>
          </Col>
        </Row>
       
        <Row
          data-aos="fade-up"
          data-aos-duration="2000"
          className="alignMiddle"
        >
          <Col sm={12} xs={12} md={4} xl={4} lg={4}>
            <div className="center">
              <img
                src={kanchandaniel}
                alt="Kanchan Daniel"
                className="new_img_ar"
              ></img>
            </div>
          </Col>
          <Col sm={12} xs={12} md={8} xl={8} lg={8}>
            <p className="artistnamem">Kanchan Daniel</p>
            <p className="aboutartist">
            Kanchan Daniel is a Bombay based musician. She is the vocalist of the homegrown blues rock band Kanchan Daniel and The Beards. The band was formed in 2012 and has since played at venues and festivals across India. Kanchan’s vocals have been described as a " raw silence that rips across a smooth serenade" and her vocals have been compared to Janis Joplin. The band's sound can be described as a mix of blues, soul, and rock. As the lead vocalist of her band, she has crafted a distinctive musical identity by seamlessly blending rock, and soul influences with a generous helping of the blues.

            </p>
          </Col>
        </Row>

        <Row
          data-aos="fade-up"
          data-aos-duration="2000"
          className="alignMiddle spacearl"
        >
          <Col className="mobile" sm={12} xs={12} md={4} xl={4} lg={4}>
            <div className="center">
              <img
                src={meganmurray}
                alt="Megan Murray"
                className="new_img_ar"
              ></img>
            </div>
          </Col>
          <Col sm={12} xs={12} md={8} xl={8} lg={8}>
            <p className="artistnamem">Megan Murray</p>
            <p className="aboutartist">
            Megan Murray is a singer-songwriter currently living in Los Angeles whom  Rolling Stone India proclaims “ will be a major performer on the world scene.”  
            Megan moved to LA in 2014 to pursue her musical dreams and has never  looked back. She studied Voice and Independent Artist Development at The  Musicians Institute in Hollywood. 
            <br></br>
            <br></br>
            Megan has worked with and been blessed to share the stage with some local  and international eminent musicians such as Merlyn D’souza, Rhys Sebastian,  Mr. Louis Banks, Nate08, Sandunes, Shemekia Copeland, Richie Cannata, Jimmy Burns, Randy  Johnson, Tomy Bowes and many more. She most recently lent her vocal talents  to reigning Queen of the Blues Shemekia Copeland's 2023 Grammy nominated  album Done Come Too Far. 
            <span id="dots3">...</span><span id="more3">
            Megan has just finished recording her first EP. Best described as an eclectic mix of Americana, Blues and Soul, her first EP was produced in Nashville by Dex Green, known for his innovative  work with Margo Price, Elvis Costello, Collective Soul and a host of other cutting edge artists. It features a virtual supergroup of the Music City’s session  elites including Jack Lawrence of the Raconteurs and current members of  Sheryl Crow’s band - Audley Freed and Jen Gunderman.  

            <br></br>
            <br></br>
            While singing is her first love, Megan has always had a deep passion for theatre and she dreams of being on Broadway someday. She currently plays the  female lead Draupadi in the musical Jaya directed by Lillette Dubey.
            <br></br>
            <br></br>
            Besides the arts, Megan also has a strong business background. She has an MBA to her credit and has worked with companies such as Fountainhead,  Oranjuice Ent., Bill Silva Ent. and Glassonion studios. 

            <br></br>
            <br></br>
           
            </span>
            <button onClick={myFunction3} className="stylereadmrebtn" id="myBtn3">Read more</button>
            </p>
          </Col>
          <Col className="desktop" sm={12} xs={12} md={4} xl={4} lg={4}>
            <div className="center">
              <img
                src={meganmurray}
                alt="meganmurray"
                className="new_img_ar"
              ></img>
            </div>
          </Col>
        </Row>
      </section>
    </div>
  );
}

export default ArtistInfo;
