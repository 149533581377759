import React, { useState } from 'react';
import '../Styles/Header.css';
import logoMbf from "../Assets/logo.svg";
import { Nav, Navbar, } from 'react-bootstrap';

function Header() {
    const [colorChange, setColorchange] = useState(false);
    const changeNavbarColor = () => {
      if (window.scrollY >= 100) {
        setColorchange(true);
      }
      else {
        setColorchange(false);
      }
    };
    window.addEventListener('scroll', changeNavbarColor);
    return (
        <div className='bgHeadM'>
            <Navbar className={colorChange ? 'navbarr colorChange' : 'navbarr'} fixed="top" collapseOnSelect expand="lg" >
                <Navbar.Brand href="/">
                    <img src={logoMbf} className="navbarLogo" alt='mahindra blues logo'/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto centerAlignNavs">
                        <Nav.Link className='navbarCustom' href="/aboutus">About Us</Nav.Link>
                        <Nav.Link className='navbarCustom' href="/artistinfo_schedule">Artist Info + Schedule</Nav.Link>
                        <Nav.Link className='navbarCustom' href="/bluesart">Blues Art</Nav.Link>
                        <Nav.Link className='navbarCustom' href="/culturaloutreach">Cultural Outreach</Nav.Link>
                        <Nav.Link className='navbarCustom' href="/photogallery">Gallery</Nav.Link>
                        <Nav.Link className='navbarCustom cntBtn' target="_blank" href="https://in.bookmyshow.com/events/mahindra-blues-festival-2025/ET00420656?webview=true">Book Tickets</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default Header